import { resolveLinkObjectToHref } from '/machinery/link'

import { ContentHeading } from '/features/buildingBlocks/ContentHeading'
import { PortableTextMainContent } from './PortableText'
import { ButtonLinkTertiary } from './Button'

import styles from './TextWithCTA.css'

export function TextWithCTA({ title, blocks, employees, internalOrExternalLink, layoutClassName = undefined }) {
  const href = resolveLinkObjectToHref(internalOrExternalLink)

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <ContentHeading layoutClassName={styles.headingLayout}>{title}</ContentHeading>
      <PortableTextMainContent value={blocks} />
      {employees && <span className={styles.employees}>{employees}</span>}
      {href && (
        <div className={styles.button}>
          <ButtonLinkTertiary label={internalOrExternalLink.label} targetSelf={internalOrExternalLink?.targetSelf} dataX='link' {...{ href }} />
        </div>
      )}
    </div>
  )
}
